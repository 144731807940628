import React from 'react';
import { graphql } from 'gatsby';

import AppGoGetLayout from '../components/AppGoGetLayout';
import HeaderSeo from '../components/HeaderSeo';
import JobPageAppBar from '../views/Jobs/JobPageAppBar';
import JobPageFooter from '../views/Jobs/JobPageFooter';
import JobSearch from '../views/Jobs/JobSearch';

const JobsHelperTemplate = props => {
  const { data } = props;
  const { datoCmsHelperType } = data;

  const handleClickOpenApp = label => {
    const url = `${process.env.GATSBY_GOGETTER_APP_URL}/home`;
    window &&
      window.analytics &&
      window.analytics.track('open_app_now', {
        label,
        page_type: 'job_listing_page',
        platform: 'static'
      });

    window.open(url, '_blank');
  };

  return (
    <AppGoGetLayout logoUrl="/gogetters" disableAppBar>
      <HeaderSeo
        title={
          datoCmsHelperType?.seoTitle ||
          `${datoCmsHelperType?.title} Jobs | GoGet Jobs`
        }
        description={datoCmsHelperType?.seoDescription}
        keywords={
          datoCmsHelperType?.seoKeywords?.length > 0 &&
          String(datoCmsHelperType.seoKeywords)
        }
        pathname={`/jobs/${datoCmsHelperType?.slug}`}
      />
      <JobPageAppBar
        logoUrl="/gogetters"
        handleClickOpenApp={handleClickOpenApp}
      />
      <JobSearch
        blurb={{
          title: datoCmsHelperType?.title,
          description: datoCmsHelperType?.description
        }}
      />
      <JobPageFooter handleClickOpenApp={handleClickOpenApp} />
    </AppGoGetLayout>
  );
};

export default JobsHelperTemplate;

export const query = graphql`
  query HelperTypeQuery($pageIdJim: String) {
    datoCmsHelperType(originalId: { eq: $pageIdJim }) {
      description
      originalId
      seoDescription
      seoKeywords
      seoTitle
      title
      slug
    }
  }
`;
